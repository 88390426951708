import React from "react";
import { injectIntl } from 'gatsby-plugin-intl';

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Hero from "../../components/Hero";
import BusinessHeroCTA from "../../components/BusinessHeroCTA";
import BucketGroupSticky from "../../components/BucketGroupSticky";
import BucketForSticky from "../../components/BucketForSticky";
import SecurityWithImage from "../../components/SecurityWithImage";
import Statistics from "../../components/Statistics";
import Questions from "../../components/Questions";

import iconPayments from "../../img/icon-payments.svg";
import iconGlobal from "../../img/icon-global.svg";
import iconHedging from "../../img/icon-hedging.svg";
import iconPartnership from "../../img/Icon-Partnership2.svg";

const BusinessPage = ({intl}) => {
  return (
    <Layout>
      <Background themeType={"dark"}>
        <Hero backgroundType={"dark"} type={"business"}>
          <BusinessHeroCTA
            headingText={intl.formatMessage({id: 'business.hero.title'})}
            description={
              intl.formatMessage({id: 'business.hero.text'})
            }
          />
        </Hero>
        <BucketGroupSticky
          headingText={intl.formatMessage({id: 'business.services.title'})}
          themeType={"dark"}
        >
          <BucketForSticky
            imageURL={iconPayments}
            title={intl.formatMessage({id: 'business.services.payments.title'})}
            description={intl.formatMessage({id: 'business.services.payments.text'})}
            themeType={"light"}
          />
          
          <BucketForSticky
            imageURL={iconGlobal}
            title={intl.formatMessage({id: 'business.services.global.title'})}
            description={intl.formatMessage({id: 'business.services.global.text'})}
            themeType={"light"}
          />

          <BucketForSticky
            imageURL={iconHedging}
            title={intl.formatMessage({id: 'business.services.hedging.title'})}
            description={intl.formatMessage({id: 'business.services.hedging.text'})}
            themeType={"light"}
          /> 

          <BucketForSticky
            imageURL={iconPartnership}
            title={intl.formatMessage({id: 'business.services.partnerships.title'})}
            description={intl.formatMessage({id: 'business.services.partnerships.text'})}
            themeType={"light"}
          /> 
          
        </BucketGroupSticky>
        <SecurityWithImage />
        <Statistics themeType={"light"} />
        <Questions />
      </Background>
    </Layout>
  );
};

export default injectIntl(BusinessPage);
