import React from "react";
import { Container } from "react-bootstrap";

import PreviewCompatibleImage from "../PreviewCompatibleImage";
import SecurityStandards from "../SecurityStandards";

import styles from "./index.module.scss";

import securityStandardsPhoto from "../../img/security-1.jpg";

class SecurityWithImage extends React.Component {
	render() {
		return (
			<Container className={styles.fixedContainer}>
				<div className={styles.imageWrapper}>
					<PreviewCompatibleImage
						imageInfo={{
							image: securityStandardsPhoto,
							alt: ""
						}}
						roundedCorners={true}
					/>
				</div>
				<div className={styles.securityWrapper}>
					<SecurityStandards />
				</div>
			</Container>
		);
	}
}

export default SecurityWithImage;
